(function () {
    'use strict';

    angular.module('aerosApp')
        .factory('SubMenuService', SubMenuService);

    SubMenuService.$inject = [];

    function SubMenuService() {

        var service = {
            registerSubmenuOnState: registerSubmenuOnState,
            subMenuItems: {}
        };

        function registerSubmenuOnState(state, subMenuItems) {
            service.subMenuItems[state] = subMenuItems;
        }

        return service;
    }
})();